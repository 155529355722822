import { ajax } from 'nanoajax';

import { ISource, IFullInfo, IMetadata, IImageOptions } from './types';

const FILE_INFO_URL: string = 'https://files.wix.com/';

const VIDEO_HOSTING_URL: string = 'https://video.wixstatic.com/';
const FILE_HOSTING_ULR: string = 'https://files.wixstatic.com/';
const STATIC_HOSTING_URL = 'https://static.wixstatic.com/';

const VIDEO_ID_REGEX = /([0-9A-Za-z_]+)/;

const getAdaptiveSource = (adaptiveInfo = []) =>
  adaptiveInfo.map(info => ({
    type: info.format.toUpperCase(),
    quality: 'ADAPTIVE',
    url: `${FILE_HOSTING_ULR}${info.url}`,
  }));

const getProgressiveSource = (videoInfo = []) =>
  videoInfo.map(info => ({
    type: info.format.toUpperCase(),
    quality: info.quality,
    url: `${VIDEO_HOSTING_URL}${info.url}`,
  }));

/**
 * Get file id from the fileUrl
 * @param fileUrl - File URL according to media gallery format
 * @doc API
 *
 * @example
 * VideoGallerySDK.getVideoId('video/80c05f_b9cca205d9324e56b2ddbd5a90ddb495/file');
 *
 * // Response example:
 * '80c05f_b9cca205d9324e56b2ddbd5a90ddb495'
 */
const getVideoId = (fileUrl: string) => {
  const splitedUrl = fileUrl.split('/');

  // Check if already truncated ID was passed
  if (splitedUrl.length === 1) {
    if (VIDEO_ID_REGEX.test(splitedUrl[0])) {
      return splitedUrl[0];
    }
    //tslint:disable-next-line
    console.error('Wrong format was passed to VideoGallerySDK.getVideoId');

    return fileUrl;
  }

  if (VIDEO_ID_REGEX.test(splitedUrl[1])) {
    return splitedUrl[1];
  }

  //tslint:disable-next-line
  console.error('Wrong format was passed to VideoGallerySDK.getVideoId');
  return fileUrl;
};

/**
 * Get full info about video item
 * @param id - id or fileUrl of video item
 * @returns Promise that would be resolved with full info about requested video item
 * @doc API
 *
 * @example
 * const id = '80c05f_b9cca205d9324e56b2ddbd5a90ddb495';
 * VideoGallerySDK.getInfo(id).then(info => {
 *   console.log(info);
 * });
 *
 * // Response example:
 * {
 *   file_input: { ... },
 *   file_name: 'file'
 *   file_output: { ... },
 *   file_size: 1267245,
 *   media_type: 'video',
 *   op_status: 'READY',
 *   original_file_name: 'file.mp4',
 *   title: 'file'
 * }
 */
const getInfo = (id: string): Promise<IFullInfo> => {
  id = getVideoId(id);

  return new Promise((resolve, reject) => {
    ajax(
      {
        url: `${FILE_INFO_URL}site/media/files/${id}/info`,
      },
      (statusCode: number, response: string) => {
        if (statusCode === 200) {
          resolve(JSON.parse(response));
        } else {
          reject({
            statusCode,
            response,
          });
        }
      },
    );
  });
};

/**
 * Get video item urls
 * @doc API
 * @param id - id or fileUrl of video item
 * @returns Promise that would be resolved with urls of requested video item
 *
 * @example
 * const id = '80c05f_b9cca205d9324e56b2ddbd5a90ddb495';
 * const player = Playable.create();
 * VideoGallerySDK.getVideoURLs(id).then(urls => {
 *   player.setSrc(urls);
 * });
 *
 * // Response example:
 * [{
 *   type: "HLS",
 *   quality: "ADAPTIVE"
 *   url: "//video.wixstatic.com/video/80c05f_b9cca205d9324e56b2ddbd5a90ddb495/repackage/hls"
 * }, {
 *   type: "MP4",
 *   quality: "480p"
 *   url: "//video.wixstatic.com/video/80c05f_b9cca205d9324e56b2ddbd5a90ddb495/480p/mp4/file.mp4"
 * }]
 *
 * @note
 * Format of answer is fully compatible with [Playable](https://wix.github.io/playable/)
 */
const getVideoURLs = (
  idOrInfo: string | IFullInfo,
): Promise<Array<ISource>> => {
  const promise =
    typeof idOrInfo === 'object'
      ? Promise.resolve(idOrInfo)
      : getInfo(idOrInfo);
  return promise.then((info: IFullInfo) => [
    ...getProgressiveSource(info.file_output.video),
    ...getAdaptiveSource(info.file_output.adaptive_video),
  ]);
};

/**
 * Get metadata for video item
 * @param id - id or fileUrl of video item
 * @returns Promise that would be resolved with metadata of requested video item
 * @doc API
 *
 * @example
 * const id = '80c05f_b9cca205d9324e56b2ddbd5a90ddb495';
 * VideoGallerySDK.getVideoMetadata(id).then(metadata => {
 *   console.log(metadata.duration);
 * });
 *
 * // Response example:
 * {
 *   "audio_bitrate": 1000,
 *   "display_aspect_ratio": "16:9",
 *   "duration": 94,
 *   "fps": 30,
 *   "height": 720,
 *   "rotation": 0,
 *   "sample_aspect_ratio": "0:0",
 *   "type": "video",
 *   "video_bitrate": 1000,
 *   "width": "1080",
 *   "size": 3000
 * }
 */
const getVideoMetadata = (idOrInfo: string | IFullInfo): Promise<IMetadata> => {
  const promise =
    typeof idOrInfo === 'object'
      ? Promise.resolve(idOrInfo)
      : getInfo(idOrInfo);
  return promise.then((info: IFullInfo) => ({
    ...info.file_input,
    size: info.file_size,
  }));
};

/**
 * Get link for video image preview. You can get on of 4 created images.
 * @doc API
 * @param id - id or fileUrl of video item
 * @param options - Options for image output
 *
 * @example
 * VideoGallerySDK.getVideoImage('video/80c05f_b9cca205d9324e56b2ddbd5a90ddb495/file', {
 *   previewIndex: 0, // index in range 0..3, one of the frame of video
 *   resizeType: 'fit', // use `fill`, `fit`, `canvas` or `crop`
 *   width: 700,
 *   height: 500,
 *   quality: 100,
 * });
 *
 * // Response example:
 * '//static.wixstatic.com/media/80c05f_b9cca205d9324e56b2ddbd5a90ddb495f000.jpg/v1/fit/w_700,h_500,al_c,q_100/image.jpg'
 */
const getVideoImage = (id: string, options: IImageOptions) => {
  const {
    previewIndex = 0,
    resizeType = 'fill',
    quality = 85,
    width,
    height,
  } = options;

  return `${STATIC_HOSTING_URL}media/${getVideoId(
    id,
  )}f00${previewIndex}.jpg/v1/${resizeType}/w_${width},h_${height},al_c,q_${quality}/image.jpg`;
};

export { getInfo, getVideoURLs, getVideoMetadata, getVideoId, getVideoImage };
